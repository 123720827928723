import {graphql, HeadFC, PageProps} from 'gatsby';
import * as React from 'react';
import ServicesPage from '../components/ServicesPage';
import {CMSData} from '../@types/CMSData';

const Services = ({data}: PageProps<CMSData>) => {
  return (
    <ServicesPage services={data.allService.nodes.sort((a, b) => b.order - a.order)} />
  );
};

export default Services;

export const Head: HeadFC = () => <title>Žvan | Storitve</title>;

export const query = graphql`
  query servicesPageQuery {
    allService {
      nodes {
        title
        description
        order
      }
    }
  }
`;
